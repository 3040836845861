import queryString from "query-string"

import {
  type LocalizationConfig,
  availableLanguages,
  baseLocalizationConfig
} from "core/modules/config/localizationConfig"

export const localizationConfig: LocalizationConfig = {
  ...baseLocalizationConfig,
  defaultLanguages: [queryString.parse(location.search).language || "en"],
  enabledLanguages: availableLanguages
}
